import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	IPageWillMountHandler,
	IPropsStore,
	Props,
	SiteFeatureConfigSymbol,
	pageIdSym,
	PageScrollRegistrarSymbol,
	SdkHandlersProvider,
} from '@wix/thunderbolt-symbols'
import { name, ReactLoaderForOOISymbol } from './symbols'
import { OOIComponentLoader, OOIPageConfig, OOISiteConfig, SetControllerProps } from './types'
import { createHostProps } from './hostProps'
import { SiteScrollBlockerSymbol, ISiteScrollBlocker } from 'feature-site-scroll-blocker'
import { IPageScrollRegistrar } from 'feature-page-scroll'
import _ from 'lodash'

export default withDependencies(
	[
		pageIdSym,
		named(PageFeatureConfigSymbol, name),
		named(SiteFeatureConfigSymbol, name),
		Props,
		ReactLoaderForOOISymbol,
		SiteScrollBlockerSymbol,
		PageScrollRegistrarSymbol,
	],
	(
		pageId,
		{ ooiComponents, accessibilityEnabled }: OOIPageConfig,
		{ ooiComponentsData, viewMode, formFactor }: OOISiteConfig,
		propsStore: IPropsStore,
		loader: OOIComponentLoader,
		siteScrollBlocker: ISiteScrollBlocker,
		{ registerToThrottledScroll }: IPageScrollRegistrar
	): IPageWillMountHandler & SdkHandlersProvider<{ setControllerProps: SetControllerProps }> => {
		return {
			getSdkHandlers() {
				return {
					setControllerProps(controllerCompId, controllerDataProps, functionNames, invokeFunction) {
						const props = controllerDataProps
						functionNames.forEach((functionName) =>
							_.set(props, functionName, (...args: any) => invokeFunction(functionName, args))
						)
						propsStore.update({
							[controllerCompId]: props,
						})
					},
				}
			},
			async pageWillMount() {
				await Promise.all(
					ooiComponents.map(async (compData) => {
						const reactComponent = await loader.loadComponent(
							ooiComponentsData[compData.widgetId].componentUrl
						)
						const hostProps = createHostProps({
							compData,
							pageId,
							accessibilityEnabled,
							formFactor,
							viewMode,
							siteScrollBlocker,
							registerToThrottledScroll,
						})
						propsStore.update({
							[compData.compId]: {
								ReactComponent: reactComponent,
								host: hostProps,
								sentryDsn: ooiComponentsData[compData.widgetId].sentryDsn,
							},
						})
					})
				)
			},
		}
	}
)
